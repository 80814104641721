import pe from "./pe.json";
import vc from "./vc.json";
import growth from "./growth.json";
import infra from "./infra.json";
import romanian from "./romanian.json";
import spanish_key_phrases from "./spanish_key_phrases.json";
import spanish_verbs_and_nouns from "./spanish_verbs_and_nouns.json";
import spanish_unit_5 from "./spanish_unit_5.json";
import spanish_unit_7 from "./spanish_unit_7.json";
import spanish_unit_7_g_2 from "./spanish_unit_7_g_2.json";
import spanish_unit_7_g_3 from "./spanish_unit_7_g_3.json";
import spanish_unit_7_g_4 from "./spanish_unit_7_g_4.json";
import spanish_unit_8 from "./spanish_unit_8.json";
import spanish_unit_9 from "./spanish_unit_9.json";
import spanish_unit_11 from "./spanish_unit_11.json";
import three_statement_basics from "./three_statement_basics.json";
import spanish_general from "./spanish_general.json"

import {
  getNextPagePath,
  currentQuestionCollection,
} from "./question_collections";

import { useNavigate, navigate } from "svelte-navigator";
import { writable, get } from "svelte/store";

let currentQuestion = writable({
  index: null,
  prompt: null,
  correct_response: null,
  response_type: null,
  prompt_content_type: null,
  response: null,
  submittedID: null,
});

function getAllQuestions(id) {
  switch (id) {
    case "pe":
      return pe;
    case "vc":
      return vc;
    case "infra":
      return infra;
    case "growth":
      return growth;
    case "romanian":
      return romanian;
    case "spanish-verbs-and-nouns":
      return spanish_verbs_and_nouns;
    case "spanish-key-phrases":
      return spanish_key_phrases;
    case "spanish-general":
      return spanish_general;
    case "spanish-unit-5":
      return spanish_unit_5;
    case "spanish-unit-7":
      return spanish_unit_7;
    case "spanish-unit-7-g-2":
      return spanish_unit_7_g_2;
    case "spanish-unit-7-g-3":
      return spanish_unit_7_g_3;
    case "spanish-unit-7-g-4":
      return spanish_unit_7_g_4;
    case "spanish-unit-8":
      return spanish_unit_8;
    case "spanish-unit-9":
      return spanish_unit_9;
    case "spanish-unit-11":
      return spanish_unit_11;
    case "three-statement-basics":
      return three_statement_basics;
    default:
      break;
  }
}

function getQuestions(collectionID, index) {
  /**
   * Returns an array of, or single, question.
   *
   * Returns one question if an index is provided.
   * Returns all questions if no index provided.
   *
   * It basically just pipes through from the JSON files.
   */
  console.debug(`Getting questions, ${collectionID}, index: ${index}`);

  // The getAllQuestions here is just used to import from the JSON files.
  let questions = getAllQuestions(collectionID);

  if (index !== undefined) {
    let question = questions[index];
    question.index = index;
    console.debug("Got question: ", question);
    return question;
  } else {
    return questions;
  }
}

function validateQuestionIndex(zeroBasedIndex, questionCollectionLength) {
  console.debug(
    "Validating question index",
    zeroBasedIndex,
    questionCollectionLength
  );
  if (zeroBasedIndex < 0) {
    throw new Error("Human question index is below 1");
  } else if (
    questionCollectionLength !== undefined &&
    zeroBasedIndex >= questionCollectionLength
  ) {
    throw new Error(
      "Human question index is greater than the length of the question collection"
    );
  } else if (zeroBasedIndex === NaN) {
    throw new Error("Could not parse a question index");
  }
}

function defaultToFirstQuestion() {
  /**
   * Navigates the browser to relative question '1' and sets the index to zero.
   */
  let navigate = useNavigate();
  navigate("1");
  return 0;
}

/**
 * Returns an integer, which is zero-based.
 * Redirects the user to question 1 if the index they try is invalid.
 * @param {integer} humanQuestionIndex - Number from the URL
 * @param {integer} questionCollectionLength - Number from the Svelte store.
 * @returns {[TODO:type]} [TODO:description]
 */
function parseHumanQuestionIndex(humanQuestionIndex, questionCollectionLength) {
  console.debug(
    "Parsing human question index:",
    humanQuestionIndex,
    questionCollectionLength
  );

  if (humanQuestionIndex === "") {
    return defaultToFirstQuestion();
  }

  let zeroBasedIndex = parseInt(humanQuestionIndex) - 1;

  try {
    validateQuestionIndex(zeroBasedIndex, questionCollectionLength);
    return zeroBasedIndex;
  } catch (error) {
    console.error("Got error. Defaulting to first question.", error);
    return defaultToFirstQuestion();
  }
}

function incrementQuestion() {
  let currentQuestionIndex = get(currentQuestion).index;
  console.debug(`Incrementing from index ${currentQuestionIndex}`);
  let nextPagePath = getNextPagePath(
    currentQuestionIndex,
    currentQuestionCollection
  );
  console.debug(`Incrementing question. Navigate to ${nextPagePath}`);
  navigate(nextPagePath);
}

export {
  currentQuestion,
  getQuestions,
  parseHumanQuestionIndex,
  incrementQuestion,
};

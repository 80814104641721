<script>
  let phoneRegex = "[+](?:[0-9]●?){6,14}[0-9]$";
</script>

<p class="font-medium bg-white p-4 rounded-md">
  <label for="phone"
    >If you'd like feedback over WhatsApp, enter your phone number below,
    including the country code.</label
  >
  <input
    class="w-full border-gray-600 border py-1 px-2 my-2"
    type="tel"
    name="phone"
    id="phone"
    placeholder="+447777777777"
    pattern={phoneRegex}
  />
</p>

<style>
</style>

<script>
  import Question from "./Question.svelte";
  import Picker from "./Picker.svelte";
  import Explanation from "./Explanation.svelte";
  import EarlyAccess from "./EarlyAccess.svelte";
  import HeaderLogo from "./HeaderLogo.svelte";
  import Tile from "./Tile.svelte";
  import Chat from "./Chat.svelte";
  import NotFound from "./NotFound.svelte";
  import Error from "./Error.svelte";

  import { Router, Route, Link } from "svelte-navigator";
</script>

<Router>
  <main class="max-w-lg mx-auto my-auto justify-center ">
    <Chat />
    <HeaderLogo />
    <Route path="/">
      <Tile>
        <Explanation>
          <span slot="heading">Nail your buyside interviews</span>
          <span slot="subtitle">
            Choose a capital stage to get started.<br />
          </span>
        </Explanation>
        <Picker collections={["pe", "growth", "vc"]}/>
      </Tile>
    </Route>
    <Route path="/all">
      <Tile>
        <Explanation>
          <span slot="heading">All</span>
          <span slot="subtitle">
            These are all the collections.<br />
          </span>
        </Explanation>
        <Picker/>
      </Tile>
    </Route>
    <Route path="/q/simple-lbo/*human_question_index">
      <Error
        >Sorry, we've temporarily taken down the LBOs. We're revamping them.
        We'd love to hear what you need. So please do reach out.</Error
      >
      <EarlyAccess />
    </Route>
    <Route path="/q/:question_collection_id/*human_question_index">
      <Tile>
        <Question />
      </Tile>
    </Route>
    <Route path="access">
      <EarlyAccess />
    </Route>
    <Route>
      <Tile>
        <NotFound />
      </Tile>
    </Route>
    <footer class="mt-1">
      <Link to="access">
        <span class="text-gray-300 text-xs underline"
          >We're building something bigger: Get early access</span
        >
      </Link>
    </footer>
  </main>
</Router>

<style global>
  /* purgecss start ignore */
  @tailwind base;
  @tailwind components;
  /* purgecss end ignore */

  @tailwind utilities;
</style>

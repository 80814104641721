<script>
  import FileResponse from "./FileResponse.svelte";
  import TextResponder from "./TextResponder.svelte";
  import SpokenResponder from "./SpokenResponder.svelte";
  import Button from "./Button.svelte";
  import {
    incrementQuestion,
    currentQuestion,
  } from "./questions/get_questions.js";
  import { db, writeToDexie } from "./dexie-db.js";
  import { currentQuestionCollection } from "./questions/question_collections.js";
  import SubmissionViewer from "./SubmissionViewer.svelte";

  /**
   * Returns a string which tells Svelte which component to render.
   * Checks first for the current question object's response type. If it doesn't have one then default to 'spoken'.
   */
  function getResponseType() {
    let responseType;

    if ($currentQuestion && $currentQuestion.hasOwnProperty("response_type")) {
      responseType = $currentQuestion["response_type"];
    } else {
      responseType = "spoken";
    }
    console.log(`Selected response type ${responseType}`);
    return responseType;
  }

  let responseConfigs = {
    file: { type: "file", component: FileResponse },
    spoken: {
      type: "spoken",
      component: SpokenResponder,
      submitHandler: incrementQuestion,
    },
    text: {
      type: "text",
      component: TextResponder,
      submitHandler: textSubmissionHandler,
    },
  };

  /**
   * Returns a component so that the user can respond to the question
   * or returns nothing if they have already submitted a response.
   */
  function getResponseComponent(responseConfigs) {
    if ($currentQuestion.submittedID) {
      return undefined;
    } else {
      console.debug(`Available configs ${JSON.stringify(responseConfigs)}`);
      console.debug(`Getting response component: ${$currentQuestion}`);

      let responseType = getResponseType();
      console.debug(`Response type is ${JSON.stringify(responseType)}`);

      let responseConfig = responseConfigs[responseType];
      console.debug(`Response config is ${JSON.stringify(responseConfig)}`);
      return responseConfig.component;
    }
  }

  /**
   * TODO refactor out along with getResponseComponent
   */
  function handleSubmit() {
    let responseType = getResponseType();
    let responseConfig = responseConfigs[responseType];
    console.debug(
      "Handling submit with",
      "Submit handler:",
      responseConfig.submitHandler,
      "Text response",
      $currentQuestion.response
    );
    responseConfig.submitHandler();
  }

  function textSubmissionHandler() {
    if ($currentQuestion.response) {
      let submittedID = writeToDexie({
        db,
        currentQuestionCollection,
      });
      $currentQuestion.response = null;
      $currentQuestion.submittedID = submittedID;
    } else {
      /* TODO handle empty response */
      console.error("No response provided");
    }
  }

  $: buttonText =
    !$currentQuestion.response | $currentQuestion.submittedID
      ? "Next"
      : "Submit";
  /* $: buttonComponent = $currentQuestion.response ? "Submit" : "Next"; */
</script>

<!-- Response component -->
<svelte:component this={getResponseComponent(responseConfigs)} />

<!-- Submissions -->
<SubmissionViewer />

<!-- TODO make it a dynamic component -->
<!-- Button component -->
{#if buttonText === "Next"}
  <Button
    on:click={() => {
      incrementQuestion();
    }}>{buttonText}</Button
  >
{:else if buttonText === "Submit"}
  <Button
    on:click={() => {
      handleSubmit();
    }}>{buttonText}</Button
  >
{/if}

<style>
</style>

<script>
	import { google, outlook, yahoo, ics } from "calendar-link";
	import { onMount } from "svelte";
	import { populateDays } from "./date-utils.js";
	import Button from "./Button.svelte";

	let eventDetails = {
		title: "Complete Quartile Simple LBO",
		start: undefined,
		duration: [1, "hour"],
		description:
			"Complete the LBO on https://www.quartiletesting.com/q/simple-lbo/1",
	};
	let reminderHidden = true;
	let toggleHiddenReminder = function () {
		reminderHidden = !reminderHidden;
		return reminderHidden;
	};

	let days = [];

	let calendarIndex;
	let calendars = [
		{ name: "Google", linkGenerator: google },
		{ name: "Outlook", linkGenerator: outlook },
		{ name: "Yahoo", linkGenerator: yahoo },
		{ name: "ICS file", linkGenerator: ics },
	];

	let generateCalendarLink = function (calendarIndex, eventDetails, calendars) {
		if (linkEnabled) {
			let link = calendars[calendarIndex].linkGenerator(eventDetails);
			return link;
		} else {
			return "#";
		}
	};
	$: linkEnabled =
		calendarIndex !== undefined && eventDetails.start !== undefined;
	$: calendarLink = generateCalendarLink(
		calendarIndex,
		eventDetails,
		calendars
	);

	onMount(() => {
		days = populateDays(days);
		console.debug(days);
	});
</script>

<div class="border-gray-300 border rounded-md px-4 py-2">
	<button
		on:click={toggleHiddenReminder}
		id="reminder-toggle"
		class="w-full text-lg font-light"
	>
		<span class="float-left">Complete it later</span><span
			hidden={!reminderHidden}
			class="float-right">▸</span
		><span class="float-right" hidden={reminderHidden}>▾</span>
	</button>
	<div class="" hidden={reminderHidden}>
		<h3 class="font-semibold mt-2">First pick a day</h3>
		<ul>
			{#each days as day, i}
				<li>
					<input
						name="day_offset"
						id={"day-" + i}
						value={day.start}
						type="radio"
						bind:group={eventDetails.start}
					/><label class="px-2" for={"day-" + i}>{day.name}</label>
				</li>
			{/each}
		</ul>
		<h3 class="font-semibold mt-2">Then create an event in your calendar</h3>
		<div class="space-x-2">
			<ul>
				{#each calendars as calendar, i}
					<li>
						<input
							name="calendar"
							id={"calendar-" + i}
							value={i}
							type="radio"
							bind:group={calendarIndex}
						/><label class="px-2" for={"calendar-" + i}>{calendar.name}</label>
					</li>
				{/each}
			</ul>
			<div class="my-2">
				<a href={calendarLink} target="_blank" class="w-full "
					><Button disabledCondition={!linkEnabled}>Create event</Button></a
				>
			</div>
		</div>
	</div>
</div>

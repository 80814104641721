<script>
	import { currentQuestion } from "./questions/get_questions.js";
	function setTextResponse() {
		currentQuestion.update((question) => {
			question["response_type"] = "text";
			return question;
		});
	}
</script>

<p class="px-4 font-light">
	Answer out loud. <button
		on:click={setTextResponse}
		class="font-light px-1 underline text-blue-900"
		>Or, write an answer <span class="">▸</span></button
	>
</p>

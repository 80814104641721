<script>
  /**
   * Gets submisisions from the input/output source.
   * Displys them according to the user's preferences.
   */
  import { liveQuery } from "dexie";
  import { db } from "./dexie-db.js";
  import Accordion from "./Accordion.svelte";
  import { currentQuestionCollection } from "./questions/question_collections.js";
  import { currentQuestion } from "./questions/get_questions.js";
  import { useParams } from "svelte-navigator";

  let params = useParams();
  $: submissions = liveQuery(() => {
    /* Just here for the reactivity */
    $params;
    return questionSubmissionsQuery();
  });

  async function questionSubmissionsQuery() {
    /* console.log( */
    /*   `Ran query with question index ${$params.human_question_index}` */
    /* ); */
    let query = db.submissions
      .where("collection")
      .equals($currentQuestionCollection.id)
      .and((item) => item.index === Number($params.human_question_index))
      .reverse()
      .sortBy("submitted_at");
    return await query;
  }
  function dateFormatter(rawString) {
    /* TODO add a locale */
    let dateObject = Date.parse(rawString);
    let formatted = Intl.DateTimeFormat("default", {
      day: "2-digit",
      month: "long",
      year: "numeric",
    }).format(dateObject);
    return formatted;
  }

  /* Show any submissions if the user has submitted an answer and either they have submitted responses or there is a correct response */
  $: showSubmissions =
    $currentQuestion.submittedID &&
    (($submissions && $submissions.length > 0) ||
      $currentQuestion.correct_response);
</script>

{#if showSubmissions}
  {#if $submissions && $submissions.length > 0}
    <Accordion>
      <span slot="accordion-title">See your answers</span>
      <div slot="accordion-content">
        {#each $submissions as submission}
          <p class="font-medium mt-2">
            {dateFormatter(submission.submitted_at)}
          </p>
          <p>{submission.response}</p>
        {/each}
      </div>
    </Accordion>
  {/if}
  {#if $currentQuestion.correct_response}
    <Accordion>
      <span slot="accordion-title">See correct answer</span>
      <div slot="accordion-content">
        <p>{$currentQuestion.correct_response}</p>
      </div>
    </Accordion>
  {/if}
{/if}

<style></style>

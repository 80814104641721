<script>
  import { onDestroy, onMount } from "svelte";

  let seconds;
  $: minutes = Math.floor(seconds / 60);

  const interval = function () {
    console.log("Started timer");
    seconds = 0;
    return setInterval(() => (seconds += 1), 1000);
  };

  const removeInterval = function () {
    clearInterval(interval);
    console.log("Removed timer");
  };
  onMount(interval);
  onDestroy(removeInterval);
</script>

{#if seconds < 60}
  <p><span>{seconds} {seconds === 1 ? "second" : "seconds"}</span></p>
{:else}
  <p><span>{minutes} {minutes === 1 ? "minute" : "minutes"}</span></p>
{/if}

<style>
</style>

<script>
  import Timer from "./Timer.svelte";
  import Progress from "./Progress.svelte";
  import Response from "./Response.svelte";
  import Reminder from "./Reminder.svelte";
  import {
    currentQuestionCollection,
    updateCollection,
  } from "./questions/question_collections.js";
  import {
    getQuestions,
    parseHumanQuestionIndex,
    currentQuestion,
  } from "./questions/get_questions.js";
  import { useParams } from "svelte-navigator";
  import { onMount } from "svelte";
  import QuestionText from "./QuestionText.svelte";

  let params = useParams();

  console.log("URL params: ", $params);

  onMount(() => {
    console.log("Store question collection ID", $currentQuestionCollection.id);
  });

  $: updateCollection($params.question_collection_id);

  $: {
    currentQuestion.set(
      getQuestions(
        $params.question_collection_id,
        parseHumanQuestionIndex(
          $params.human_question_index,
          $currentQuestionCollection.count
        )
      )
    ),
      console.log(
        `Updated store currentQuestion: ${JSON.stringify($currentQuestion)}`
      );
  }
</script>

{#if currentQuestion["prompt_content_type"] === "html"}
  <div class="font-medium text-lg bg-white p-4 rounded-md">
    {@html $currentQuestion.question_content}
  </div>
{:else}
  <QuestionText>
    <div class="font-medium text-lg bg-white rounded-md">
      {$currentQuestion.prompt}
    </div>
  </QuestionText>
{/if}
{#if $currentQuestion.hasOwnProperty("download_path")}
  <p class="font-medium bg-white p-4 rounded-md">
    <a class="underline" href={$currentQuestion.download_path} download
      >Download template here.</a
    >
  </p>
  <Reminder />
{/if}
<Response {currentQuestion} {currentQuestionCollection} />
<div class="flex flex-row space-x-2 text-sm">
  <Progress>
    <span slot="current"> {$params.human_question_index} </span>
    <span slot="count">{$currentQuestionCollection.count}</span>
  </Progress>
  <br />
  <Timer />
</div>

<style>
</style>

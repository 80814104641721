<script>
  let labelText = "📂️ Upload your Excel";

  let updateWithFileName = function (event) {
    let filename = event.target.value.split("\\").pop();
    labelText = `📂️  ${filename}`;
  };
</script>

<p class="font-medium bg-white p-4 rounded-md">
  <label
    for="upload"
    class="block p-2 w-full border border-gray-800 border-dashed cursor-pointer "
    >{labelText}</label
  >
  <input
    on:change={(e) => updateWithFileName(e)}
    type="file"
    name="lbo-excel"
    id="upload"
    accept=".xls,.xlsx"
    required
  />
</p>

<style>
  [type="file"] {
    border: 0;
    clip: rect (0, 0, 0, 0);
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    white-space: nowrap;
    width: 1px;
  }

  [type="file"] + label {
  }
</style>

<script>
  let emailRegex = "[^@ \t\r\n]+@[^@ \t\r\n]+.[^@ \t\r\n]+";

  function getPlaceholder() {
    let placeholders = [
      "jbelfort@strattonoakmont.com",
      "willyg@microsoft.com",
      "krav@kkr.com",
      "dj.d-sol@gs.com",
      "dick.fuld@lehman.com",
      "skilling@enron.com",
    ];
    let random = Math.floor(Math.random() * placeholders.length);
    let placeholder = placeholders[random];
    return placeholder;
  }
  let placeholder = getPlaceholder();
</script>

<div id="response" class="font-medium bg-white p-4 rounded-md ">
  <label for="email">Enter your email below.</label>
  <input
    class="w-full border-gray-600 border py-1 px-2 my-2"
    type="email"
    id="email"
    name="email"
    placeholder={placeholder}
    pattern={emailRegex}
    required
  />
</div>

<style>
</style>

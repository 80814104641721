<script>
	import SecondaryHeading from "./SecondaryHeading.svelte";
	import { fade } from "svelte/transition";

	let reminderHidden = true;
	let toggleHiddenReminder = function () {
		reminderHidden = !reminderHidden;
		return reminderHidden;
	};
</script>

<div class="border-gray-300 border rounded-md px-4 py-2">
	<button
		on:click={toggleHiddenReminder}
		id="reminder-toggle"
		class="w-full text-lg font-light"
	>
		<SecondaryHeading>
			<span class="float-left"><slot name="accordion-title" /></span><span
				hidden={!reminderHidden}
				class="float-right">▸</span
			><span class="float-right" hidden={reminderHidden}>▾</span>
		</SecondaryHeading>
	</button>
	<div class="" hidden={reminderHidden} transition:fade>
		<slot name="accordion-content" />
	</div>
</div>

<script>
  import { currentQuestion } from "./questions/get_questions";
</script>

<textarea
  class="w-full h-32 p-1 rounded-md"
  bind:value={$currentQuestion.response}
  placeholder="Type your response. We'll save it to this device."
/>

<style>
</style>

import { writable } from "svelte/store";
import { getQuestions } from "./get_questions.js";

let allQuestionCollections = [
  /**This is effectively the database of all question collections.
   * So in future it will disappear.
   * It's also not quite clear why it should be necessary now.*/
  { id: "pe", name: "Private Equity" },
  { id: "infra", name: "Infrastructure" },
  { id: "growth", name: "Growth Capital" },
  { id: "vc", name: "Venture Capital" },
  { id: "romanian", name: "Romanian" },
  { id: "spanish-key-phrases", name: "Spanish key phrases" },
  { id: "spanish-verbs-and-nouns", name: "Spanish verbs and nouns" },
  { id: "spanish-unit-5", name: "Spanish Unit 5" },
  { id: "spanish-unit-7", name: "Spanish Unit 7" },
  { id: "spanish-unit-7-g-2", name: "Spanish Unit 7.G.2: Reflexive verbs" },
  { id: "spanish-unit-7-g-3", name: "Spanish Unit 7.G.3: Followed by a preposition" },
  { id: "spanish-unit-7-g-4", name: "Spanish Unit 7.G.4: Expressions of frequency" },
  { id: "spanish-unit-8", name: "Spanish Unit 8" },
  { id: "spanish-unit-9", name: "Spanish Unit 9" },
  { id: "spanish-unit-11", name: "Spanish Unit 11" },
  { id: "spanish-general", name: "Spanish, General"},
  { id: "three-statement-basics", name: "Three statement model: the basics" },
];

function getQuestionCollections(ids) {
  /** Returns an object with data about each of the relevant collections.
   * Takes zero or more IDs.
   * If it's an array, return a matching array of question collection objects. Preserves order.
   * If it's a string, return just that object.
   * Else, return them all.
   *
   * Used by the Picker to find out which collections to display.
   */
  let selectedQuestionCollections;
  switch (Object.prototype.toString.call(ids)) {
    case "[object Array]":
      selectedQuestionCollections = allQuestionCollections
        .filter((c) => ids.includes(c.id))
        .sort((a, b) => ids.indexOf(a.id) - ids.indexOf(b.id));
      break;
    case "[object String]":
      selectedQuestionCollections = allQuestionCollections.filter(
        (c) => c.id === ids
      )[0];
      break;
    default:
      selectedQuestionCollections = allQuestionCollections;
  }
  return selectedQuestionCollections;
}

let currentQuestionCollection = writable({
  id: null,
  name: null,
  count: null,
});

function updateCollection(collectionID) {
  /** Given an ID, sets the store to the current collection. */
  console.log("Updating with", collectionID);
  try {
    let selectedQuestionCollection = allQuestionCollections.filter(
      (c) => c.id === collectionID
    )[0];
    selectedQuestionCollection.count = getQuestions(collectionID).length;
    currentQuestionCollection.set(selectedQuestionCollection);
  } catch (error) {
    console.error("Could not update collection:", error);
  }
}

function getNextPagePath(currentIndex, currentQuestionCollection) {
  console.debug(
    `Getting next page path`,
    `Current question index: ${currentIndex}`
  );
  let isFinal = currentIndex + 1 === currentQuestionCollection.count;
  let finalPagePath = "/access";

  let nextPagePath = isFinal ? finalPagePath : String(currentIndex + 2);

  return nextPagePath;
}

export {
  allQuestionCollections,
  getQuestionCollections,
  currentQuestionCollection,
  updateCollection,
  getNextPagePath,
};

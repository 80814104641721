<script>
  import {
    getQuestionCollections,
    updateCollection,
  } from "./questions/question_collections.js";
  import Button from "./Button.svelte";
  import { useNavigate, useFocus } from "svelte-navigator";

  let registerFocus = useFocus();
  let navigate = useNavigate();
  export let collections;

  let questionCollections = getQuestionCollections(collections);
  let selectedID;
  $: console.debug("Selected", selectedID);

  let navigateToFirstQuestion = function (selectedID) {
    console.debug("Navigating to", selectedID);
    navigate(`/q/${selectedID}/1`);
  };
</script>

<ul class="flex flex-col space-y-2">
  {#each questionCollections as questionCollection}
    <label>
      <input
        type="radio"
        bind:group={selectedID}
        value={questionCollection.id}
        use:registerFocus
        class="focus:shadow-sm"
      />
      <span class="p-2">{questionCollection.name}</span>
    </label>
  {/each}
</ul>
<Button
  disabledCondition={selectedID === undefined}
  on:click={() => navigateToFirstQuestion(selectedID)}>Let's go</Button
>

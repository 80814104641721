<script>
  export let disabledCondition;
  export let buttonType;

  import { useFocus } from "svelte-navigator";
  let registerFocus = useFocus();
</script>

<button
  use:registerFocus
  class="
  p-3
  bg-yellow-400
  text-black
  border-gray-400
  disabled:bg-yellow-100
  disabled:text-gray
  disabled:opacity-50
  disabled:cursor-not-allowed
  active:bg-blue-900
  font-semibold
  w-full"
  tabindex="0"
  on:click
  disabled={disabledCondition}
  type={buttonType}
>
  <slot />
</button>

<style>
</style>

import Dexie from "dexie";
import { v4 as uuidv4 } from "uuid";
import { get } from "svelte/store";
import { currentQuestion } from "./questions/get_questions";
import { currentQuestionCollection } from "./questions/question_collections";

const db = new Dexie("quartile");
db.version(1).stores({
    submissions:
        "++submission_id, submitted_at, collection, index, prompt, response, time_taken",
});

function buildPayload() {
    let submissionID = uuidv4();
    let submittedAt = new Date(Date.now()).toISOString();
    let collection = get(currentQuestionCollection).id;
    let question = get(currentQuestion);
    let payload = {
        submission_id: submissionID,
        collection: collection,
        submitted_at: submittedAt,
        index: question.index + 1,
        prompt: question.prompt,
        response: question.response,
        time_taken: "Timer currently broken.",
    };

    return payload;
}

/**
 * Takes current data about the user's response and saves it
 * locally.
 * @async
 * @param {[TODO:type]} [TODO:name] - [TODO:description]
 * @param {[TODO:type]} [TODO:name].db - [TODO:description]
 * @param {[TODO:type]} [TODO:name].currentQuestion - [TODO:description]
 * @param {[TODO:type]} [TODO:name].response - [TODO:description]
 * @returns {Promise<[TODO:type]>} [TODO:description]
 */
async function writeToDexie({ db }) {
    try {
        let payload = buildPayload();
        let submittedID = await db.submissions.add(payload);
        console.log(`Added ${submittedID} to database.`);
        return submittedID;
    } catch (error) {
        console.error(
            `Failed to add ${currentQuestionCollection} - ${currentQuestion.index} - ${error}`,
            error.stack
        );
    }
}

export { db, writeToDexie };

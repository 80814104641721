// Utilities to enable Reminders

let addDays = function (days) {
    let date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
};

let setTime = function (date) {
    let newDate = new Date(date.valueOf());
    newDate.setHours(19);
    newDate.setMinutes(0);
    newDate.setSeconds(0);
    return newDate;
};

let createDays = function (numberDays, todayDate) {
    let days = [];
    for (let i = 1; i <= numberDays; i++) {
        let subsequentDate = todayDate.addDays(i);
        let day = {};
        // Set subsequent day
        day.start = subsequentDate.toISOString();
        day.name = subsequentDate.toLocaleDateString("default", {
            weekday: "long",
        });
        console.debug("Created date:", day);
        days.push(day);
    }
    return days;
};

let populateDays = function (days) {
    Date.prototype.addDays = addDays;
    // Today
    let todayDate = new Date();
    todayDate = setTime(todayDate);
    // Coming week
    days = createDays(7, todayDate);
    return days;
};

export { addDays, setTime, populateDays };
